<template>
  <div class="login-container">
    <div class="login-box">
      <center>
        <h4
          style="letter-spacing: 5px; margin: 12px; font-size: 24px; font-weight: 500"
        >
          吉林科学技术出版社
        </h4>
        <h2
          style="letter-spacing: 5px; margin: 12px; font-size: 32px; font-weight: 500;"
        >
          图书销售平台
        </h2>
        <h4
          style="letter-spacing: 5px; margin: 12px; font-size: 24px; font-weight: 500"
        >
          欢迎登录
        </h4>
      </center>
      <v-container>
        <v-row>
          <v-col :cols="6" align-self="center" offset="3">
            <v-text-field
              v-model="user_info.username"
              dense
              label="请输入用户名"
              solo
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row style="margin-top: -40px">
          <v-col :cols="6" align-self="center" offset="3">
            <v-text-field
              v-model="user_info.password"
              dense
              label="请输入密码"
              solo
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
<!--        <v-row style="margin-top: -60px">-->
<!--          <v-col :cols="6" align-self="center" offset="3">-->
<!--            <v-checkbox label="记住我"></v-checkbox>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row style="margin-top: -10px;margin-bottom: 10px">
          <v-col :cols="6" align-self="center" offset="3">
            <v-btn color="primary" style="width: 100%" @click="submit"
              >登录</v-btn
            >
          </v-col>
        </v-row>
<!--        <v-row style="margin-top: -10px">-->
<!--          <v-col :cols="6" align-self="center" offset="3">-->
<!--            <span-->
<!--              style="vertical-align:middle ;line-height: 20px;font-size: 12px;"-->
<!--              >没有账号? 注册一个</span-->
<!--            >-->
<!--            <span-->
<!--              class="iconfont"-->
<!--              style="vertical-align:middle ;line-height: 30px;font-size: 16px; cursor: pointer"-->
<!--              @click="jump"-->
<!--              >&#xe631;</span-->
<!--            >-->
<!--          </v-col>-->
<!--        </v-row>-->
        <hr />
        <p class="login-copyright">
          <span>©</span>
          <span>2020</span>
          吉林科学技术出版社 版权所有
        </p>
      </v-container>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/users";
import { mapMutations } from "vuex";

export default {
  name: "login",
  data: () => ({
    user_info: {
      username: "",
      password: "",
    },
  }),
  methods: {
    ...mapMutations(["setUser"]),
    jump() {
      this.$router.push({ name: "registration" });
    },
    submit() {
      login(this.user_info)
        .then((res) => {
          console.log("登陆结果 res = ", res);
          localStorage.setItem("token", res.access);

          localStorage.setItem("user", res.user);
          this.setUser(res.user);
          this.$router.push({ name: "admin_home" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {},
};
</script>
<style scoped type="stylus">
.login-container {
  height: 100vh;
  background-image: url("/assets/images/login_bg.jpg");
  background-repeat: no-repeat;
  background-size: dimensional;
}

.login-box {
  width: 800px;
  height: 520px;
  background: rgba(255, 255, 255, 0.8);
  margin: 120px auto;
  box-shadow: 0 0 10px grey;
  border-radius: 4px;
  padding-bottom: 14px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 28px;
}

.login-copyright {
  text-align: center;
  font-size: 12px;
  color: gray;
  margin-top: 10px;
}
</style>
